@import "./reduce-motion";

.loader {
  display: none;
  height: 330px;
  justify-content: center;
}

.loader--loading {
  display: flex;

  .loader__icon {
    &:before {
      animation: rotate 1s linear infinite;
    }
  }
}

.loader__icon {
  --dim: 81px;
  --loader-scale: 58px;

  background-color: white;
  border-radius: 50%;
  box-shadow: 0 5px 5px rgba(0,0,0,.3);
  height: var(--dim);
  margin-top: 80px;
  position: relative;
  width: var(--dim);

  &::before {
    background-image: image-url("common/icon-loading.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: "";
    height: var(--loader-scale);
    left: 50%;
    margin-left: calc(var(--loader-scale) * -0.5);
    margin-top: calc(var(--loader-scale) * -0.5);
    position: absolute;
    top: 50%;
    width: var(--loader-scale);
  }
}

@keyframes rotate {
  to {
    transform: rotate(359deg);
  }
}

@media #{$medium-up} {
  .loader {
    height: 428px;
  }
}

@include reduce-motion {
  .loader__icon {
    &::before {
      animation-play-state: paused;
    }
  }
}
