@mixin reduce-motion() {
  @include reduce-motion-query {
    @content;
  }

  @include reduce-motion-class {
    @content;
  }
}

@mixin reduce-motion-query {
  @media #{$reduce-motion-query} {
    @content;
  }
}

@mixin reduce-motion-class {
  .is-reduced-motion {
    @content;
  }
}

@function get-color($color) {
  @return map-get($colors, $color);
}

@mixin calc($property, $expression) {
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: calc(#{$expression});
}

@mixin center-absolute($trans, $img: '', $retina: true) {
  position: absolute;
  top: 50%;
  left: 50%;

  @if $trans {
    transform: translate(-50%, -50%);
  }
  @else {
    $div: 2;

    @if $retina {
      $div: 4;
    }

    margin-left: -(image-width($img)/$div);
    margin-top: -(image-height($img)/$div);
  }
}

@mixin full-absolute($inset: 0) {
  bottom: $inset;
  left: $inset;
  position: absolute;
  right: $inset;
  top: $inset;
}