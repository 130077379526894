// Helpers
span.nobr {
  white-space: nowrap;
}

.font-fallback {
  @include font-Lato();

  transform: translateY(-0.5em);
  font-size: 0.5em;
  display: inline-block;
}

.text-center {
  text-align: center;
}

@each $color, $hex in $colors {
  .text-#{$color} {
    color: $hex;
  }
}

.text-shadow {
  text-shadow: 2px 2px 5px rgba(0,0,0,0.8);
}

.img-block {
  display: block;
}

.img-full {
  display: block;
  width: 100%;
}

.default-radius {
  border-radius: 15px;
}

.default-shadow {
  box-shadow: 0 3px 2px 0 rgba(0,0,0,0.30);
}

.default-text-shadow {
  text-shadow: 0 3px 2px rgba(0,0,0,0.30);
}

.speech-bubble {
  $img: 'news/news-speechbubble.png';
  background-image: image-url($img);
  background-size: 100% 100%;
  position: relative;
  z-index: 21;
  padding: 20px 30px 30px 30px;
}


@mixin pattern-bg($color) {

  $img: '';
  $gradientcolor: '';

  @if $color == 'blue' {
    $img: 'patterns/blue-squares.png';
    $gradientcolor: #5174FC;
  }

  @if $color == 'orange' {
    $img: 'patterns/orange-triangles.png';
    $gradientcolor: #F47F33;
  }

  @if $color == 'green' {
    $img: 'patterns/green-triangles.png';
    $gradientcolor: #01A623;
  }

  background-color: $gradientcolor;
  background-image: image-url($img);
  background-size: image-width($img)/2 image-height($img)/2;
  background-position: 50% 28%;

  > * {
    position: relative;
    z-index: 10;
  }

  &:before {
    content: '';
    background-image: linear-gradient(-180deg, rgba(244,127,51,0.00) 0%, $gradientcolor 98%);
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 400px;
  }
}

.img-inline-frame {
  border: solid 4px #fff;
}

// thick frame with corner pieces
.fancy-border {
  position: relative;
  display: block;
  // border: solid 10px #fff;
  overflow: visible;
  max-width: calc(100% - 25px);
  margin-left: auto;
  margin-right: auto;

  nclood-video {
    position: relative;
    z-index: 499;
  }

  &:before {
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto auto;
    width: 100%;
    height: 100%;
    border: solid 10px #fff;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  &:after {
    content: '';
    $lt: 'common/corner-left-top_2x.png';
    $rt: 'common/corner-right-top_2x.png';
    $lb: 'common/corner-left-bottom_2x.png';
    $rb: 'common/corner-right-bottom_2x.png';
    background-image: image-url($lt), image-url($rt), image-url($lb), image-url($rb);
    background-position: 0 0, 100% 0, 0 100%, 100% 100%;
    background-size: image-width($lt)/2 image-height($lt)/2;
    background-repeat: no-repeat;
    top: 0;
    bottom: 0;
    right: 0;
    left: -10px;
    margin: auto;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    position: absolute;
    z-index: 500;
  }

  &.modal__content {
    max-width: 100%;
  }
}


// section cap with arc and leaves or other foliage
.foliage-cap {
  $foliage: 'common/tree-border-top_2x.png';
  position: absolute;
  top: 0;
  width: 100%;
  height: image-height($foliage)/2;
  overflow: hidden;

  // foliage
  &:before {
    content: '';
    background-image: image-url($foliage);
    background-repeat: repeat-x;
    background-size: image-width($foliage)/2 image-height($foliage)/2;
    position: absolute;
    display: block;
    top: 0;
    width: 100%;
    height: image-height($foliage)/2;
  }

  // arc
  &:after {
    content: '';
    width: 150%;
    left: -25%;
    right: 0;
    padding-bottom: 25%;
    position: absolute;
    margin: 0 auto;
    bottom: 65%;
    background-color: #F47F33;
    border-radius: 50%;
    box-shadow: 0px 10px 10px rgba(0,0,0,.4);
  }

  @media #{$xlarge-up} {
    &:after {
      width: 175%;
      left: -37.5%;
    }
  }

  @media #{$xxlarge-up}  {
    &:after {
      width: 175%;
      left: -37.5%;
      bottom: 55%;
    }
  }
}

.foliage-cap--apple {
  $foliage: 'common/apple-border-top_2x.png';
  height: image-height($foliage)/2;

  &:before {
    background-image: image-url($foliage);
    background-size: image-width($foliage)/2 image-height($foliage)/2;
    height: image-height($foliage)/2;
  }
  &:after {
    background-color: #FDD119;
    // top: 0;
  }
}

.row-flex {

    @media #{$small-only} {
        padding-left: 10px;
        padding-right: 10px;
    }
    @media #{$medium-only} {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.pattern {
  background-position: 50% 50%;
}

.pattern--triangles {
  $img1x: "patterns/triangles-yellow.png";
  $img2x: "patterns/triangles-yellow-2x.png";

  background-image: image-url($img1x);
  background-size: image-width($img1x) auto;

  @media #{$retina} {
    background-image: image-url($img2x);
  }
}

.rel {
  position: relative;
}