section.play {
  $img: "patterns/play-dots.png";

  position: relative;
  background-image: image-url($img);
  background-color: #fdfdea;
  background-size: image-width($img) / 2 image-height($img) / 2;
  z-index: 50;
  padding-bottom: 85px;

  h2 {
    text-align: center;
  }

  .play__container {
    width: 100%;

    @media screen and (min-width: 61.5em) {
      width: 70%;
    }
  }

  .playframe__frame {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 200%;
    overflow: hidden;

    iframe {
      border: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  @media only screen and (min-width: 44.5em) {
    .playframe__frame {
      padding-bottom: percentage(411/738);
      max-width: 990px;
      margin: 0 auto;
    }
  }

  @media (min-width: 690px) and (max-width: 711px) {
    .playframe__frame {
      padding-bottom: 50%;
    }
  }

  @media #{$medium-up} {
    padding-bottom: 105px;
  }

  @media #{$large-up} {
    padding-bottom: 125px;
  }
}
.section--play__char {
  display: none;
  position: relative;
  .play-char img {
    position: absolute;
    display: block;
    opacity: 0;
    transition: transform 0.5s cubic-bezier(0.02, 1.65, 0.59, 0.96),
      opacity 0.25s ease-out;
    transform: translateX(50%);
    right: -15%;
    bottom: -5%;
  }

  .vp-on-30 & {
    .play-char img {
      opacity: 1;
      transform: translateX(0);
    }
  }
}
.section--play__content {
  position: relative;
  z-index: 5;
}

@media (min-width: 1000px) {
  section.play {
    .section--play__char {
      display: block;
    }
  }
}

.is-ios section.play .playframe__frame {
  width: 1px;
  min-width: 100%;
  iframe {
    width: 1px;
    min-width: 100%;
  }
}

@include reduce-motion {
  .section--play__char .play-char img {
    transform: none;
    transition-property: opacity;
  }
}
