@mixin font-Lato() {
  font-family: 'Lato', 'Open sans', 'Helvetica', sans-serif;
}

@mixin font-Arvo() {
  font-family: 'Arvo', serif;
}
// Usage
// http://codepen.io/macx/full/qmkje
//
// .dark-on-light {
//   @include font-smoothing(off);
// }
//
// .light-on-dark {
//   @include font-smoothing(on);
// }
@mixin font-smoothing($value: on) {
  @if $value == on {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}
