// @FUNCTION
// $colNumber - Found in settings file
// $totalColumns - Found in settings file
@function grid-calc($colNumber, $totalColumns) {
  $result: percentage(($colNumber / $totalColumns));
  @if $result == 0% { $result: 0; }
  @return $result;
}

// RANGES
// We use these functions to define ranges for various things, like media queries.
@function lower-bound($range) {
  @if length($range) <= 0 {
    @return 0;
  }
  @return nth($range, 1);
}

@function upper-bound($range) {
  @if length($range) < 2 {
    @return 999999999999;
  }
  @return nth($range, 2);
}

// STRIP UNIT
// It strips the unit of measure and returns it
@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

// REM CALC
@function rem-calc($values, $base-value: $rem-base)
{
  $max: length($values);

  @if $max == 1 { @return convert-to-rem(nth($values, 1), $base-value); }

  $remValues: ();
  @for $i from 1 through $max {
    $remValues: append($remValues, convert-to-rem(nth($values, $i), $base-value));
  }
  @return $remValues;
}

/// Converts a pixel value to matching rem value. *Any* value passed, regardless of unit, is assumed to be a pixel value. By default, the base pixel value used to calculate the rem value is taken from the `$global-font-size` variable.
/// @access private
///
/// @param {Number} $value - Pixel value to convert.
/// @param {Number} $base [null] - Base for pixel conversion.
///
/// @returns {Number} A number in rems, calculated based on the given value and the base pixel value. rem values are passed through as is.
@function -zf-to-rem($value, $base: null) {
  // Check if the value is a number
  @if type-of($value) != 'number' {
    @warn inspect($value) + ' was passed to rem-calc(), which is not a number.';
    @return $value;
  }

  // Calculate rem if units for $value is not rem
  @if unit($value) != 'rem' {
    $value: strip-unit($value) / strip-unit($base) * 1rem;
  }

  // Turn 0rem into 0
  @if $value == 0rem {
    $value: 0;
  }

  @return $value;
}

// CONVERT TO REM
@function convert-to-rem($value, $base-value: $rem-base)
{
  $value: strip-unit($value) / strip-unit($base-value) * 1rem;
  @if ($value == 0rem) { $value: 0; } // Turn 0rem into 0
  @return $value;
}
