// intro hero

.page-home {
  // Specifics here

  section.hero {
    background: image-url("home/hero-bg-desktop.jpg") center center/cover
      no-repeat;
    padding-top: 0;
    // padding-top: 100px; // compensate for stickynav
    position: relative;
    z-index: 29;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    video {
      display: none;
      position: absolute;
      top: 0;
      height: 100%;
      min-width: 100%;
      left: 50%;
      transform: translate(-50%);
      backface-visibility: hidden;

      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#822fbe+0,f4ab7c+100 */
      // background: #822fbe; /* Old browsers */
      // background: -moz-linear-gradient(top, #822fbe 0%, #f4ab7c 100%); /* FF3.6-15 */
      // background: -webkit-linear-gradient(top, #822fbe 0%,#f4ab7c 100%); /* Chrome10-25,Safari5.1-6 */
      // background: linear-gradient(to bottom, #822fbe 0%,#f4ab7c 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#822fbe', endColorstr='#f4ab7c',GradientType=0 ); /* IE6-9 */

      @media #{$small-only} {
        display: none;
      }
    }

    .hero-video--ended {
      opacity: 0;
      transition: opacity 1s linear 0s;
    }

    .alps-os-android & {
      @media #{$medium-only} {
        $img: "home/hero-bg.jpg";
        background: image-url($img);
        background-size: cover;
        background-position: 50% 50%;

        video {
          display: none !important;
        }
      }
    }

    .no-autoplay & {
      $img: "home/hero-bg-desktop.jpg";

      background: image-url($img);
      background-size: cover;
      background-position: 50% 50%;

      video {
        display: none !important;
      }
    }

    div {
      backface-visibility: hidden !important;
    }

    @media #{$small-only} {
      $img: "home/hero-bg.jpg";
      background: image-url($img);
    }
  }

  .hero__content {
    max-width: 100%;
    margin: 0 auto;
    left: 0;
    right: 0;
    text-align: center;
    opacity: 0.99999;
    width: 100%;
    height: 100%;
  }

  .scene__copy {
    position: relative;
    z-index: 29;

    // speech bubbles
    div {
      @extend .speech-bubble;
      float: right;
      right: -45px;
      top: 50px;
      padding: 40px 50px;
      width: 275px;
      font-size: 0.75em;
    }

    p {
      font-size: 2.5em;
      margin: 0;
    }
  }

  .scene__char {
    display: block;
    float: left;
    position: relative;
    z-index: 30;
    width: 35%;
    max-width: 325px;
  }

  .scene {
    display: flex;
    flex-direction: row;
    justify-content: center;
    bottom: 50px;
    transform: translateY(100%);
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    margin-left: 15px;
    margin-right: 15px;
    max-width: 100%;
    height: 100%;
    z-index: 500;
    backface-visibility: hidden;
    transform-style: preserve-3d;

    &:nth-of-type(even) {
      .scene__copy {
        div {
          float: left;
          left: -50px;
        }
      }

      .scene__char {
        display: block;
        float: right;
      }

      .scene4__copy {
        z-index: 20;

        div {
          left: 35px;
          top: 220px;
        }
      }
    }

    .scene3__copy {
      z-index: 20;

      div {
        right: -30px;
        top: 280px;
      }
    }

    .scene4__logo {
      margin: 0 auto 40px;
      max-width: 60%;
      position: relative;
      min-height: 200px;

      span {
        line-height: 1.4;
        white-space: nowrap;
        text-align: center;
        width: 100%;
        font-size: 1.25em;
        font-weight: bold;
      }

      img {
        max-width: 100%;
        display: block;
        margin: 10px auto 0;
      }
    }
  }

  .scene4__sign {
    // margin-top: 50px;
    $img: "home/sign_2x.png";
    background-image: image-url($img);
    background-size: 100%;
    background-size: 115% 100%;
    background-position: 50% 50%;
    position: relative;
    padding: 10px 25px 60px 25px;
    color: #fff;
    width: 100%;
    max-width: 300px;
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);

    margin: 0 auto;

    p {
      line-height: 1.5em;
    }
  }

  .scene__signage {
    position: relative;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    // mobile only
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

@media #{$small-only} {
  .page-home {
    section.hero {
      // padding-bottom: 80px;
      // min-height: calc(100vh - 35px);
      height: auto;
      $img: "home/hero-bg.jpg";
      background-image: image-url($img);
      background-size: cover;
      background-position: 50% 50%;
    }

    .scene {
      flex-direction: column;
      justify-content: center;
      position: relative;
      height: auto;
      top: 0;
      width: 100%;
      padding-top: 25px;
      padding-bottom: 25px;
      margin-left: 0;
    }

    .scene__signage {
      flex-direction: column;
      z-index: 30;
      height: 100%;
    }

    .scene1,
    .scene2,
    .scene3 {
      display: none;
    }

    .scene4__sign {
      margin-top: 200px;
    }
    .scene {
      .scene4__logo,
      .scene4__header,
      .scene4__sign {
        position: relative !important;
      }
    }

    //TEMP

    .scene4__copy {
      display: none !important;
    }

    .scene4__char {
      position: absolute;
      width: 75%;
      bottom: 0;
      z-index: 29;
      float: none;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      bottom: 140px;
      width: 75%;
      max-width: 175px;

      img {
        width: 100%;
      }
    }
  }
}

@media #{$medium-only} {
  .page-home {
    section.hero {
      height: auto;
      background-position: 50% 50%;
      background-size: auto 100%;
    }

    .scene__char img {
      max-width: 325px;
    }
    .scene__copy div {
      width: 275px;
      font-size: 0.75em;
    }

    .scene3__copy div {
      top: 220px;
    }

    .scene4__sign {
      max-width: 75%;
      padding: 10px 85px 150px 85px;

      p {
        font-size: 1.125em;
      }
    }

    .scene__signage {
      bottom: 100px;
    }

    .scene .scene4__logo {
      width: 300px;
      margin-bottom: 70px;
    }
  }
}
@media #{$medium-up} {
  .page-home {
    section.hero {
      position: relative;
      width: 100%;
      height: 650px; // without sticky nav
      // margin-top: 67px; // with sticky nav
      padding-top: 20px;

      video {
        display: block;
        position: absolute;
        top: 0;
        height: 100%;
        min-width: 100%;
        left: 50%;
        transform: translate(-50%);
      }
    }

    .hero__content {
      margin: 0 auto;
      left: 0;
      right: 0;
      text-align: center;

      .scene {
        bottom: -180px;
      }

      .scene1__copy div {
        top: 70px;
      }

      .scene:nth-of-type(even) .scene2__copy div {
        top: 140px;
        // left: 40px !important;
        left: 40px;
      }

      .scene3__char {
        bottom: -60px;
      }

      .scene3,
      .scene4 {
        bottom: -50px;
      }

      .scene4__char {
        bottom: -50px;
      }
    }

    .scene4 {
      opacity: 0;
    }

    .scene3__copy div {
      top: 306px;
      transform: translateX(30px);
    }

    .scene4__logo {
      max-width: none;
      max-width: 75%;
      top: 70px;
      width: 100%;
    }

    .scene4__sign {
      width: 500px;
      padding: 25px 120px 100px;
      max-width: 100%;
      margin-top: 50px;
    }

    .scene__signage {
      position: absolute;
    }
  }
}

@media #{$large-up} {
  .page-home {
    section.hero {
      height: 750px;
    }

    .scene {
      bottom: -128px;
    }

    .scene__copy div {
      float: right;
      right: -100px;
      top: 50px;
      width: 375px;
      font-size: 1em;
      padding: 40px 50px;
    }

    .scene2__copy div {
      top: 140px;
      // left: 40px !important;
      left: 50px;
    }

    .scene3__copy div {
      top: 400px;
    }

    .hero__content .scene:nth-of-type(even) .scene2__copy div {
      top: 140px;
      // left: 40px !important;
      left: 50px;
    }

    .scene__signage {
      height: 85%;
    }

    .scene4__logo {
      top: -20px;
    }

    .scene4__sign {
      padding: 50px 65px 140px 65px;
      top: 20px;
    }
  }
}

@media (min-width: 1100px) {
  .page-home {
    .hero__content {
      max-width: 1200px;
    }

    // .char-isabelle {
    //   display: block;
    // }
  }
}

@include reduce-motion {
  // .page-home section.hero video {
  //   display: none;
  // }
}
