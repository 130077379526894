.page-home {
  .yellow-bg-wrap {
    background-image: linear-gradient(-180deg, #fbbb37 0%, #fdd119 100%);
    padding-top: 80px;
    position: relative;

    // IE 11 rounding issue fix for bottom of yellow section
    &:before {
      content: "";
      position: absolute;
      bottom: -1px;
      background-color: #fdd119;
      height: 1px;
      width: 100%;
      left: 0;
      right: 0;
      margin: 0 auto;
      z-index: 35;
    }
  }

  .feature-pattern {
    background-position: 50% 12px;
    height: 352px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .section--news {
    padding: 0 0 60px;
  }

  .news-title {
    margin-bottom: 24px;
  }

  .news-grid {
    margin-bottom: 8px;
  }

  .feature-image .fancy-border {
    width: 100%;

    &::before,
    &::after {
      z-index: 1;
      pointer-events: none;
    }
  }

  .fancy-border--iframe {
    position: relative;
    overflow: visible;

    &::before {
      content: "";
      display: block;
      padding-bottom: 55%; // slightly larger than 16/9 to accommodate borders
      position: relative;
    }

    iframe,
    nclood-video {
      position: absolute;
      top: 10px;
      left: 10px;
      width: calc(100% - 20px);
      height: calc(100% - 20px);
      border: none;
      z-index: 0;
    }
  }

  .videos-list {
    margin-top: 40px;
    @media #{$large-up} {
      padding-left: 0;
      margin-top: 0;
    }

    .cards {
      justify-content: center;
    }

    .button-container {
      text-align: center;

      .button {
        max-width: 93%;
      }
    }
  }

  .section--pocket-camp {
    --inset-size: 36px;
    --border-radius-large: 16px;
    --border-radius: 8px;

    color: #6b5c43;
    margin-block: 40px 48px;
    text-align: center;

    .pattern-blade {
      position: relative;

      padding-top: 64px;
      padding-bottom: 64px;

      &::before {
        $bg: "patterns/pattern-stripes.jpg";
        $bg2x: "patterns/pattern-stripes-2x.jpg";

        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        content: "";

        background-image: image-url($bg);
        background-size: image-width($bg) image-height($bg);

        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);

        @media #{$medium-up} {
          border-radius: var(--border-radius-large);
        }
      }

      &::after {
        $bg: "patterns/pattern-trees.jpg";
        $bg2x: "patterns/pattern-trees-2x.jpg";
        position: absolute;
        top: var(--inset-size);
        bottom: var(--inset-size);
        left: 12px;
        right: 12px;
        content: "";
        border-radius: var(--border-radius);

        background-image: image-url($bg);
        background-size: image-width($bg) image-height($bg);
      }

      & > * {
        position: relative;
        z-index: 1;
      }
    }

    img {
      max-width: 100%;
    }
  }

  .pocket-camp__logo {
    margin-bottom: -52px;
    position: relative;
    z-index: 1;
  }

  .pocket-camp__text {
    margin-top: 1rem;

    > * + * {
      margin: 0;
      margin-top: 1rem;
    }
  }

  .pocket-camp__title {
    font-size: 1.5rem;
    margin: 0;
  }

  .pocket-camp__body {
    font-size: 1rem;
  }
}

@media #{$small-only} {
  .page-home {
    section.news-videos {
      .button {
        max-width: 95%;
      }
    }

    .section--pocket-camp {
      .row-flex,
      [class^="column-flex"] {
        padding-inline: 0;
      }

      .column-deep {
        padding-inline: 40px;
      }
    }
  }
}

@media #{$medium-up} {
  .page-home {
    .section--news {
      padding: 0;
    }

    .section--pocket-camp {
      .pattern-blade {
        &::after {
          left: var(--inset-size);
          right: var(--inset-size);
        }
      }
    }
  }
}

@media #{$medium-only} {
  .page-home {
    .videos-list .button-container .button {
      max-width: 550px;
    }

    .section--pocket-camp {
      .pattern-blade {
        display: block;
      }
    }

    .pocket-camp__media {
      margin-inline: auto;
      max-width: none;
      width: MIN(50%, 290px);
    }

    .pocket-camp__text {
      margin-inline: auto;
      max-width: none;
      padding-inline: 0;
      width: MIN(72%, 360px);
    }
  }
}

@media #{$large-up} {
  .page-home {
    .section--pocket-camp {
      .row-flex {
        align-items: center;
      }
    }

    .pocket-camp__media {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .pocket-camp__logo {
      margin-inline: auto;
      width: 84%;
    }
    .pocket-camp__text {
      margin-top: 0;
    }

    .pocket-camp__title {
      font-size: 1.9rem;
      line-height: 1.2;
      margin: 0;
    }
  }
}

@media #{$xlarge-up} {
  .page-home {
    .videos-list {
      .button-container {
        text-align: center;

        .button {
          max-width: 75%;
        }
      }
    }
  }
}

@import "home-intro";
