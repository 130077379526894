.birthday {
  position: relative;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: -30px;
  margin-top: -50px;
  z-index: 30;
}

// background imagery
.birthday__stripe {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  $img: 'patterns/birthday-red_2x.jpg';
  background-position: center top;
  background-image: image-url($img);
  background-size: image-width($img)/2 image-width($img)/2;
  width: 100%;
  height: calc(100% - 40px);
}

.birthday__ribbon {
  $img: 'birthday-ribbon.svg';

  position: relative;
  background-image: image-url($img);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  transform: scale(0);
}


// birthday content
.birthday__content {
  position: relative;
  $img: 'birthday-ribbon.svg';
  margin: 0 auto;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 90%;
  bottom: 5px;
  flex-wrap: wrap;
  @include font-Arvo();

  @media #{$medium-up} {
    flex-wrap: nowrap;
    justify-content: space-around;
    padding-bottom: 0;
  }
}

// birthday controls and name
.birthday__copy {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  margin: 25px 0 20px 0;

  @media #{$medium-up} {
    width: 50%;
    flex-wrap: nowrap;
    margin: 0;
  }
}

.birthday__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
}

// 'today is'
.birthday__label {
  color: get-color('yellow');
  font-weight: bold;
  text-shadow: 0 2px 2px rgba(0,0,0,0.30);
}

.birthday__name {
  font-size: 1.375em;
  color: #fff;
  font-weight: bold;
  @extend .default-text-shadow;
  max-width: 350px;
  line-height: 1.35;
  // white-space: nowrap;
  margin-bottom: 5px;
}

.birthday__character-image {
  position: relative;
  max-height: 100%;
  margin-top: -5px;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: auto;
    max-height: 100%;
  }

  @media #{$medium-up} {

  }
}

.birthday__confetti {
  $img: 'birthday/confetti_2x.png';
  background-image: image-url($img);
  position: absolute;
  background-size: image-width($img)/2 image-height($img)/2;
  width: image-width($img)/2;
  height: image-height($img)/2;
  z-index: -1;
  opacity: 1;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: block;
  transform: scale(1);
}

.birthday__nav {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 130px;

  // transform: scale(0);

  button {
    width: 46px;
    height: 46px;
    border-radius: 15px;
    display: block;
    background-color: #C4161C;
    border: none;
    position: relative;
    box-shadow: 0 3px 2px rgba(0,0,0,.30);
    transition: opacity .15s ease-in-out;
    cursor: pointer;

    &::after {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      $arrow: 'birthday/birthday-arrow.svg';
      background-image: image-url($arrow);
      background-size: image-width($arrow) image-width($arrow);
      background-repeat: no-repeat;
      background-position: center center;
    }

    &:nth-of-type(2)::after {
      transform: rotate(180deg);
    }

    &:hover:not(.is-disabled),
    &:focus:not(.is-disabled) {
      background-color: #a71015;
    }

    &:focus {
      box-shadow: 0 3px 2px rgba(0,0,0,.30), 0 0 6px 6px #FFE46A;
    }

    &:active {
      box-shadow: 0 1px 1px rgba(0,0,0,.5);

      &:focus {
        box-shadow: 0 1px 1px rgba(0,0,0,.5), 0 0 6px 6px #FFE46A;
      }
    }

    &.is-disabled {
      opacity: .5;
      cursor: default;

      &:active {
        box-shadow: 0 3px 2px rgba(0,0,0,.30);
      }
    }
  }
}


// birthday cheer button
.birthday__cheer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  width: 40%;

  button {
    border: none;
    padding: 0;
    background: none;
    display: block;
    color: get-color('yellow');
    text-decoration: none;
    font-weight: bold;
    text-align: center;
    position: relative;
    max-height: 100%;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      display: block;
      border-radius: 50%;
      background-color: #C4161C;
      box-shadow: 0px 5px 8px rgba(0,0,0,.25);
      z-index: 49;
      width: 125px;
      height: 125px;

    }

    div {
      position: relative;
      z-index: 50;
      width: 100%;
      // background-color: blue;
      margin: 0 auto;
      left: 0;
      right: 0;
      display: block;
      width: 125px;
      height: 125px;
      top: 0;
    }

    &:hover::before {
      // background-color: lighten(#C4161C, 2%);
      background-color: #a71015;
    }

    &:active {
      transform: translate(1px, 1px);

      &::before {
        box-shadow: 0px 2px 4px rgba(0,0,0,.5);
      }
    }

    .label {
      line-height: 1.25;
      display: block;
    }
  }

  img {
    display: block;
    margin: 0 auto;
  }
}

@media #{$small-only} {

  .birthday {
    .birthday__character-image {
      margin-top: 0;
      margin-bottom: 30px;
      width: 100%;
    }
    .birthday__cheer {
      position: absolute;
      bottom: 5%;
      right: 2%;
      width: 25%;
      display: none;

      img {
        position: relative;
        width: 75px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .birthday__confetti {
      max-height: 89%;
      max-width: 90%;
    }

    .char {
      max-height: 250px;
    }

  }
}

@media #{$medium-up} {

  .birthday {

    .birthday__content {
      padding-left: 20px;
      padding-right: 20px;
    }

    .birthday__confetti {
      left: -20%;
      bottom: 5%;
      max-height: 89%;
    }

    .birthday__cheer {
      // width: 150px;
      width: 30%;

      // .label {
      //   display: none;
      // }

      img {
        max-width: 85%;
      }

      button::before {
        width: 150px;
        height: 150px;

      }

      div {
        width: 150px;
        position: relative;
        top: -24px;
        transform: none;
      }
    }

    .birthday__name {
      font-size: 1.75em;
      margin-top: 10px;
      margin-bottom: 0;
    }

    .birthday__nav {
      margin-top: 20px;
    }

    .birthday__label {
      font-size: 1.125rem;
    }

    .char {
      max-width: 250px;
    }

    .birthday__character-image {
      // width: 40%;
      align-items: flex-start;
    }
  }
}

@media #{$large-up} {

  .birthday {

    .birthday__name {
      font-size: 1.85rem;
    }

    .birthday__confetti {
      // max-height: 100%;
      // min-width: 100%;
      left: -30%;
    }

    .birthday__cheer {

      button::before {
        width: 185px;
        height: 185px;

      }

      div {
        width: 185px;
        position: relative;
        top: -10px;
        transform: none;
      }
    }
  }
}

// confetti styles

#confetti-parent {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 1;

  transition: opacity .5s ease-in;

  &.timeout {
    opacity: 0;
  }

  #confetti {
    overflow: visible;
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    display: block;
  }

  @keyframes confetti-slow {
    0% { transform: translate3d(0, 0, 0) rotateX(0) rotateY(0); }

    100% { transform: translate3d(25px, 105vh, 0) rotateX(360deg) rotateY(180deg); }
  }

  @keyframes confetti-medium {
    0% { transform: translate3d(0, 0, 0) rotateX(0) rotateY(0); }

    100% { transform: translate3d(100px, 105vh, 0) rotateX(100deg) rotateY(360deg); }
  }

  @keyframes confetti-fast {
    0% { transform: translate3d(0, 0, 0) rotateX(0) rotateY(0); }

    100% { transform: translate3d(-50px, 105vh, 0) rotateX(10deg) rotateY(250deg); }
  }

  .confetti-container {
    position: absolute;
    overflow: hidden;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .confetti {
    position: absolute;
    z-index: 1;
    top: -10px;
    border-radius: 0%;

    &--animation-slow {
      animation: confetti-slow 2.25s linear 1 forwards;
    }

    &--animation-medium {
      animation: confetti-medium 1.75s linear 1 forwards;
    }

    &--animation-fast {
      animation: confetti-fast 1.25s linear 1 forwards;
    }
  }
}

#mySVG {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4000;
}


@include reduce-motion() {
  .birthday__ribbon {
    transform: scale(1);
  }

  .birthday__cheer {
    display: none;
  }
}
