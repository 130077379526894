.modal {
  z-index: 101;

  &.is-open {
    z-index: 10001; // cover global nav
  }
}

.modal__wrapper {
  border: solid 0px transparent;
}

.modal__content {
  padding: 10px;
  @extend .fancy-border;
}

.modal--retailer {
  .modal__wrapper {
    width: 90%;
  }

  .modal__content {
    height: 70vh;
    padding: 10px;
    background: white;
  }
}

.modal--video {

  .modal__overlay {
    background: rgba(#032064, .75);
  }
}

.modal__close {
  width: 38px;
  height: 38px;
  border-radius: 20px;
  $close: 'common/modal-close.svg';
  background: image-url($close) center no-repeat get-color('yellow');
  padding: 0;
  margin: 0 0 0 auto;
  transform: translate(15px, 30px);
  z-index: 100;
  font: 0/0 a;
  overflow:hidden;
  z-index: 11500;
  right: 10px;
  top: -90px;
  position: absolute;

  &:hover {
    background: image-url($close) center no-repeat get-color('yellow-light');
  }

  &:after {
    display: none;
  }

  @media #{$small-only} {
    right: 10px;
    top: -90px;
  }
}

@media #{$small-only} {
  .modal__wrapper {
    max-width: 100%;
    padding: 0;
  }

  .modal__content {
    margin: 0;
    padding: 10px;
    border: none;
  }
}

@media #{$large-up} {
  .modal__wrapper {
    max-width: 900px;
  }
  .modal__close {
    top: -80px;
    right: -22px;
  }
}

@media screen and (min-height: 500px) {
  .modal--retailer {
    .modal__content {
      height: 440px;
      padding: 20px 10px;
    }
  }
}