@import "./reduce-motion";

@mixin vp-animate {
  .vp-onf-30 & {
    @content;
  }
}

.vp-slide {
  opacity: 0;

  @include vp-animate {
    opacity: 1;
    transform: translate(0, 0);
    transition: transform 0.4s ease, opacity 0.4s ease;
  }
}

.vp-pop {
  opacity: 0;
  transform: scale(0.5);

  @include vp-animate {
    opacity: 1;
    transform: scale(1);
    transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55),
      opacity 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
}

.vp-slide--right {
  transform: translateX(-50%);
}

.vp-slide--left {
  transform: translateX(50%);
}

.vp-slide--down {
  transform: translateY(-50%);
}

.vp-slide--up {
  transform: translateY(30px);
}

@for $i from 1 through 10 {
  .vp-delay-#{$i} {
    @include vp-animate {
      transition-delay: #{$i/10}s;
    }
  }
}

@include reduce-motion() {
  .vp-slide,
  .vp-pop {
    transform: translate(0, 0) scale(1);

    @include vp-animate {
      transition-property: opacity;
    }
  }
}
