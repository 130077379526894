.nclood-nav-wrap {
  position: relative;
  z-index: 70;
}

header {
  position: relative;
}

.section--news {
  text-align: center;
}

ul[data-news] {
  list-style-type: none;
  padding: 0;
  margin: 0 auto;
}

.news-article__modal-content {
  display: none;
}

.footer-main {
  $footer-img: "patterns/footer-dots.png";

  padding: 170px 0 20px;
  position: relative;
  z-index: 51;
  background: image-url($footer-img) get-color("orange");
  background-size: image-height($footer-img) * 1.5 image-width($footer-img) *
    1.5;
}

.footer-main__decor {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  &::before {
    $img1x: "common/fir-tree-border.png";
    $img2x: "common/fir-tree-border-2x.png";

    background-image: image-url($img1x);
    background-size: image-width($img1x) auto;
    content: "";
    height: image-height($img1x);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    @media #{$retina} {
      background-image: image-url($img2x);
    }
  }

  &::after {
    $pattern1x: "patterns/birthday-red.jpg";
    $pattern2x: "patterns/birthday-red_2x.jpg";

    background-color: get-color("red");
    background-image: image-url($pattern1x);
    background-size: image-width($pattern1x) auto;
    content: "";
    display: block;
    height: 20px;
    position: relative;

    @media #{$retina} {
      background-image: image-url($pattern2x);
    }
  }
}

.footer-main__lang {
  text-align: center;
  color: white;
  padding-top: 20px;
  padding-bottom: 30px;

  p {
    font-size: 1.5rem;
  }
}

.footer-main__share {
  @include display-flex(flex);
  @include align-items(center);
  @include flex-direction(column);

  margin-bottom: 30px;
  padding: 0 ($column-gutter/2);
  text-align: center;
}

.footer-main__share__facebook,
.footer-main__share__support {
  font-size: 1rem;
  padding: 15px 45px;
  font-weight: 700;

  &.footer-main__share__support {
  }
}

.footer-main__share__facebook {
  @include display-flex(inline-flex);
  @include align-items(center);
  @include justify-content(center);
  @extend .default-radius;
  background: rgba(131, 75, 22, 0.75);
  color: white;

  margin-bottom: 20px;

  p {
    margin-right: 12px;
    margin-top: 0;
    margin-bottom: 0;
  }

  nclood-social,
  .fb_iframe_widget,
  .fb_iframe_widget span {
    display: block;
  }
}

.footer-main__share__support {
  @include display-flex(inline-flex);
  @include align-items(center);

  margin: 0 auto;
}

.footer-main__links {
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.footer-main__legal {
  color: black;
  text-align: center;

  p {
    font: normal 12px Arial, Helvetica, sans-serif;
    margin-bottom: 3px;
  }
}

@media #{$medium-up} {
  .footer-main__share {
    @include flex-direction(row);
    @include justify-content(center);
    @include align-items(initial);
  }

  .footer-main__share__support {
    font-size: 1.1rem;
    padding: 14px 80px;
  }

  .footer-main__share__facebook {
    margin-right: 30px;
    margin-bottom: 0;

    p {
      // font-size: 1.625rem;
    }
  }

  .footer-main__share__support {
    margin: 0;
  }
}

@media #{$large-up} {
  .footer-main__share__facebook {
    position: relative;
  }
}
