// Styles here are different enough to warrant a News-Modal-specific stylesheet :)

@import "../../mixins/helpers";

$news-modal-backgrounds: (
  "blue": ("squares", #5174FB),
  "green": ("triangles", #01A623),
  "purple": ("squares", #873ADC)
);

.news-modal-bg {
  @include full-absolute;

  opacity: 0;
  position: fixed;
  transition: opacity 0.4s linear 0s, z-index 0s linear 0.4s;
  z-index: -10;
}

.news-modal-bg--shown {
  opacity: 1;
  transition: opacity 0.4s linear 0s, z-index 0s linear 0s;
  z-index: 60; // gets us above the footer
}

@each $color-label, $style in $news-modal-backgrounds {
  $pattern: nth($style, 1);
  $hex: nth($style, 2);

  .news-modal-bg--#{$pattern}-#{$color-label} {
    $img1x: "";
    $img2x: "";

    @if $pattern == "squares" {
      $img1x: "patterns/squares.png";
      $img2x: "patterns/squares-2x.png";
    } @else {
      $img1x: "patterns/#{$pattern}-#{$color-label}.png";
      $img2x: "patterns/#{$pattern}-#{$color-label}-2x.png";
    }

    background-color: $hex;
    background-image: image-url($img1x);
    background-size: image-width($img1x) auto;

    @media #{$retina} {
      background-image: image-url($img2x);
    }
  }
}

body.news-modal-open {
  overflow: hidden;
}

.modal--news {
  --top-offset: 0;

  overflow: scroll;
  z-index: 65;

  .modal__overlay {
    display: none;
  }

  .modal__close {
    display: none;
  }

  .modal__focus-start {
    box-shadow: none;
  }

  .modal__wrapper {
    max-height: 100%;
    max-width: 926px;
    overflow: visible;
  }

  .modal__content {
    background: transparent;
    padding: 120px 0 80px;
    text-align: center;

    &::before,
    &::after {
      display: none;
    }
  }

  .news-article__modal-content-inner {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 5px 5px 0 rgba(0,0,0,0.30);
    padding: 0 16px;
    text-align: left;
  }

  .news-article__title {
    font-size: 1.3em;
    line-height: 1.25;
    margin: 0 auto 16px;
    padding: 0;
    text-align: center;
  }

  .news-article__date {
    font-size: 0.9rem;
    font-weight: bold;
    margin-bottom: 28px;
    padding: 0;
    text-align: center;
  }

  .news-article__thumb-wrapper {
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.50);
    margin-bottom: 40px;
    max-width: none;
    width: 100%;
  }

  .news-article__thumb {
    box-shadow: none;
    display: block;
    margin-bottom: 0;
    opacity: 1;
    width: 100%;
  }

  .news-article__body {
    line-height: 1.6;
    margin-bottom: 48px;

    a {
      color: get-color("teal-2");
    }

    p {
      margin-bottom: 0;

      & + p {
        margin-top: 32px;
      }
    }

    ul {
      margin: 24px 0 40px;
    }

    li + li {
      margin-top: 12px;
    }

    img {
      width: 100%;
    }
  }

  .news-article__legal {
    margin-bottom: 48px;

    p {
      font-size: 0.85rem;

      & + & {
        margin-top: 1em;
      }
    }
  }
}

.news-article__logo {
  display: inline-block;
  margin: 0 auto 48px;
  width: 200px;

  img {
    display: block;
    width: 100%;
  }
}

@media #{$small-only} {
  .modal--news {
    .modal__wrapper {
      height: 100%;
      left: 0;
      max-height: none;
      max-width: none;
      overflow: hidden;
      top: 0;
      transform: none;
      width: 100%;
    }

    .modal__content {
      height: 100%;
      max-height: none;
      overflow: auto;
    }
  }

  .news-article__body {
    p a {
      // handle really long text URL links
      word-break: break-word;
    }
  }
}

@media #{$medium-up} {
  .modal--news {
    .modal__wrapper {
      margin-top: var(--top-offset);
    }

    .modal__content {
      margin-bottom: 100px;
      overflow: visible;
      padding: 36px 0 100px;
    }

    .news-article__modal-content-inner {
      padding: 0 75px;
    }

    .news-article__title {
      font-size: 1.9em;
    }

    .news-article__thumb-wrapper {
      position: relative;
      left: -40px;
      width: calc(100% + 80px);
    }
  }

  .news-article__logo {
    margin-bottom: 64px;
    width: auto;
  }
}

@media #{$xlarge-up} {
  .modal--news {
    .news-article__thumb-wrapper {
      left: -100px;
      width: calc(100% + 200px);
    }
  }
}

@include reduce-motion {
  .modal--news {
    .modal__content {
      transition-property: opacity;
    }
  }
}
