.featured-games {
  text-align: center;
  position: relative;
  padding-top: 125px;
  padding-bottom: 0;
  background-image: linear-gradient(-180deg, #6ddcff 13%, #ffffff 100%);
}

.foliage-cap {
  z-index: 24;
}

.featured-games__heading {
  color: #834b16;
  // font-size: 2.25rem;
  position: relative;
  z-index: 25;
}

.featured-games__list {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
  flex-wrap: nowrap;
  overflow-x: scroll;
  min-height: 390px;
  padding: 0;
  position: relative;
  z-index: 30;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: transparent;
    -webkit-border-radius: 6px;
  }

  @media #{$small-only} {
    margin-left: 5px;
  }

  // overflow: -moz-scrollbars-none;
}

.featured-games__item {
  position: relative;
  // width: 80%;
  min-width: 85%;

  &:nth-of-type(2) .featured-games__copy {
    display: block;
  }
}

.featured-games__item-wrap {
  display: flex;
  flex-direction: column;
  position: relative;
  top: 0;
  left: 0;
  background-color: #fff;
  padding: 0px;
  border-radius: 15px;
  transform: scale(0.98);
  transition: transform 0.25s ease-out;
  width: 100%;
  z-index: 26;
  text-decoration: none;

  @media #{$medium-up} {
    transform: scale(0.965);
  }
}

.featured-games__image {
  line-height: 0;
  overflow: hidden;
  border-radius: 10px;
  margin: 8px;
  position: absolute;

  img {
    width: 100%;
  }

  .featured-games__details & {
    margin: 0 0 5px 0;
    position: relative;
  }
}

.featured-games__details {
  border-radius: 15px;
  position: relative;
  top: 0;
  padding: 8px;
  width: 100%;
  overflow: hidden;
  transition: max-height 0.25s ease-out;
  @extend .default-shadow;

  span {
    display: block;
  }
}

.featured-games__platform {
  color: get-color("blue");
  display: inline-block;
  float: left;
  font-size: 0.8125rem;
  line-height: 2rem;
}

.featured-games__price {
  display: none;
  float: right;
  color: #9b9b9b;
}

.featured-games__title {
  display: block;
  clear: both;
}

.featured-games__cta {
  display: block;
  margin-top: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 15px;
  margin-bottom: 12px;
}

.featured-games__platform-tag-wrap {
  position: absolute;
  bottom: -32px;
  left: 0;
  right: 0;
  display: block;
  text-align: center;
  display: none;

  span {
    font-size: 0.8125rem;
    color: get-color("blue");
    display: inline-block;
    background: rgba(255, 255, 255, 0.6);
    padding: 6px 25px;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
    display: inline-block;
  }
}

.featured-games__more {
  display: block;
  margin-top: 5%;
  margin-bottom: 60px;
  color: get-color("blue");
  position: relative;
  z-index: 25;

  p {
    display: block;
    margin: 0;
    font-size: inherit;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  a {
    font-weight: bold;
    text-decoration: none !important;
    position: relative;
    display: block;

    span {
      &:last-child {
        display: none;
      }
    }

    &:last-of-type:after {
      display: none;
    }

    &:after {
      content: "/";
      font-weight: normal;
      position: relative;
      right: -20px;
      display: none;
    }

    &:hover {
      color: lighten(get-color("blue"), 5%);
    }
  }
}

@media #{$medium-up} {
  .featured-games__list {
    min-height: 0;
  }

  .featured-games__item {
    min-width: 33%;
  }

  .featured-games__more {
    align-items: center;
    font-size: 1.125rem;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  .featured-games__item-wrap {
    font-size: 1.125rem;
  }
}

@media #{$large-up} {
  .featured-games__more {
    align-items: center;
    justify-content: center;
    margin-top: 60px;
    display: flex;

    a {
      font-weight: bold;
      text-decoration: none !important;
      position: relative;
      display: block;

      span {
        &:last-child {
          margin-left: 20px;
          display: inline;
        }
      }
    }

    ul {
      align-items: center;
      display: flex;
    }

    li {
      margin-left: 10px;
      margin-right: 10px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .featured-games__item-wrap {
    font-size: 1.125rem;
  }
}

@media #{$xlarge-up} {
  .featured-games__more {
    a {
      display: inline;

      &:last-of-type:after {
        display: none;
      }

      &:after {
        content: "/";
        font-weight: normal;
        position: relative;
        right: -20px;
        display: inline-block;
      }
    }
  }

  .featured-games__more {
    p {
      display: inline;
    }
  }
}

// balloon char

.featured-games {
  $img: "common/char-balloon_2x.png";

  .char--balloon-wrap {
    pointer-events: none;
    position: absolute;
    top: 28%;
    z-index: 20;
    width: 100%;
    height: (image-height($img) / 2) + 25px;
  }

  .char--balloon-container {
    display: block;
    height: 100%;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 0;
    width: image-width($img) / 2;
  }

  .char--balloon {
    position: relative;
    background-image: image-url($img);
    width: image-width($img) / 2;
    height: image-height($img) / 2;
    background-size: image-width($img) / 2 image-height($img) / 2;
    visibility: hidden;
    animation: 8s balloonfloat ease-in-out infinite alternate;
    left: calc(50% + 410px);
    right: 0;
  }
}

@keyframes balloonfloat {
  0% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(50px);
  }
}

@media #{$large-up} {
  .featured-games {
    .featured-games__list {
      min-height: 0;
      overflow: visible;
      overflow-x: visible;
      overflow-y: visible;
    }

    .featured-games__platform-tag-wrap {
      display: block;
    }

    .featured-games__item-wrap {
      &:hover,
      &:focus {
        transform: scale(1);

        .featured-games__image {
          img {
            transform: scale(1.02);
          }
        }

        .featured-games__details {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    .featured-games__item {
      width: 33%;
      min-width: 0;
    }

    .featured-games__image {
      position: relative;

      img {
        transition: transform 0.1s ease-out;
      }
    }

    .featured-games__details {
      background-color: #fff;
      position: absolute;
      visibility: hidden;
      overflow: hidden;
    }
  }
}

@media #{$xlarge-up} {
  .featured-games {
    .char--balloon {
      visibility: visible;
    }
  }
}

@include reduce-motion {
  .char--balloon-wrap {
    display: none;
  }

  .featured-games .featured-games__item-wrap {
    transition: none;
  }

  .featured-games {
    .featured-games__image {
      img {
        transition: none;
      }
    }
  }
}
