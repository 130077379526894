.twitter {
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
  margin-top: -50px;
  z-index: 50;

  @media #{$medium-up} {
    padding-top: 65px;
  }

  @media #{$large-up} {
    padding-top: 135px;
  }
}

.twitter__bg {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -150%;
    width: 400%;
    height: 1000px;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    background-color: #01a623;
    background-image: image-url("patterns/green-triangles.png");
    background-size: 156px 154.5px;
    background-position: 50% 28%;
    z-index: -1;

    @media #{$large-up} {
      height: 3000px;
    }
  }
}

.ac-twitter {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  .row-flex-large {
    position: relative;
    margin-top: 25px;
    text-align: center;
  }

  .speechbubble {
    $img: "common/facebook-speechbubble_2x.png";
    color: get-color("brown");
    background: image-url($img) center center/100% 100% no-repeat;
    position: relative;
    z-index: 21;
    padding: 20px 40px 30px;
    margin: 0 auto;
    width: 60%;
    margin-left: 5%;
    text-align: center;
  }

  h3 {
    line-height: 1.5;
    margin-bottom: 8px;
    margin-bottom: 0;
    font-size: 1em;
  }

  .char {
    position: absolute;
    bottom: 0;
    z-index: 25;

    img {
      max-height: 100%;
    }
  }

  .char--mabel {
    left: -25px;
    z-index: 26;
    bottom: -60px;
    max-width: 200px;
  }

  .char--harriet {
    right: 72%;
    bottom: -25px;
  }

  .char--lyle {
    left: 60%;
    max-width: 220px;
    display: block;
    width: 40%;
    bottom: -60px;

    img {
      max-width: 100%;
    }
  }

  .char--mabel,
  .char--harriet {
    display: none;
  }

  @media #{$small-only} {
    .char--lyle {
      max-width: 150px;
    }
  }

  @media #{$medium-up} {
    h3 {
      font-size: 1.85em;
    }

    .char--lyle {
      max-width: 225px;
    }

    .char--lyle {
      display: block;
      left: 72%;
    }

    .ac-twitter {
      line-height: 1.5;
    }

    .char--mabel {
      display: block;
      width: 20%;
    }

    .speechbubble {
      max-width: 425px;
      margin-left: auto;
    }
  }

  @media (min-width: 1025px) {
    .char--harriet {
      display: block;
    }
  }

  @media (min-width: 760px) {
    .speechbubble {
      max-width: 500px;
    }
  }

  @media #{$large-up} {
    .char--mabel {
      display: block;
    }

    .speechbubble {
      max-width: 625px;
    }

    .char--mabel {
      z-index: 26;

      img {
        left: 0;
        position: relative;
      }
    }

    .char--lyle {
      position: absolute;
      left: 70%;
    }
  }
}
