$colors: (
  "orange": #f7aa18,
  "green": #46b03e,
  "dark-green": #004d0f,
  "red": #c4161c,
  "red-nav": #dd1733,
  "blue": #007f88,
  "blue-light": #afebff,
  "teal": #36afb6,
  "yellow": #ffe46a,
  "yellow-body": #fbbb37,
  "yellow-light": #fdf378,
  "yellow-dark": #e29502,
  "brown": #834b16,
  "gray": #505050,
  "dark-gray": #464646,
  "purple": #cd5aff,
);
