$card-radius: 10px;

.card-container {
	display: flex;
	margin-bottom: 20px;
}

.card,
a.card {
	background: #fff;
	border-radius: $card-radius;
	cursor: pointer;
	display: block;
	padding: 10px;
	text-decoration: none;
	width: 100%;
	position: relative;
	color: get-color('brown');
	@extend .default-shadow;

	&.card--video {
		text-align: center;
	}

	&:hover {
		text-decoration: none;
	}

	.card__hero__image {
		display: block;
		transition: transform 0.25s ease-in-out;
		width: 100%;
		border-radius: $card-radius;
	}

	.card__title {
		font-size: rem-calc(18px);
		font-weight: 900;
		margin: 0.5em 0 0;
		// line-height: 1.5;
	}

	.card__date {
		font-size: rem-calc(14px);
		font-weight: bold;
		color: get-color('blue');
	}

	.card__copy {
		font-weight: normal;
		margin-top: .5rem;
		text-align: left;
	}
}

.card__hero {
	margin-bottom: 10px;
	overflow: hidden;
	position: relative;
	// play circle
	.icon--play {
		background: rgba(255,255,255,.65);
		@extend .default-shadow;
		border-radius: 50%;
		height: 77px;
		width: 77px;
		transition: background .25s ease-out;
		// play triangle
		&:after {
			$playtriangle: 'common/icon-play-triangle.svg';
			content: '';
			backface-visibility: hidden;
			background-image: image-url($playtriangle);
      margin-left: 4px;
			transition: transform 0.25s ease-in-out;
			width: 25*1.25px;
			height: 27*1.25px;
		}
	}

	.icon--play,
	.icon--play:after {
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: 100%;
		background-size: 100%;
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
	}
}

.card--news {
	text-align-last: left;
}


// hover states
.card-container:hover {
	.card__hero__image {
		backface-visibility: hidden;
		transform: scale(1.05);
	}

	.icon--play {
		background: rgba(255,255,255,1);

		&::after {
			transform: translate(-50%, -50%) scale(1.25);
			transition-delay: 0.05s;
		}
	}
}

@media #{$medium-only} {

	.card__copy {
		font-size: 1.125rem;
	}
}
@media #{$large-up} {
	// video tile animations

	.card-container {
		opacity: 0;
		transition: opacity .25s ease-out, transform .25s ease-out;
	}

	.card-container:nth-of-type(3n+1) {
		transform: translate(-40%, 0);
	}

	.card-container:nth-of-type(3n+3) {
		transform: translate(40%, 0);
	}

	.page-home .videos-list .card-container {
		transform: translate(40%, 0);
	}

	.card-container:nth-of-type(3n+2) {
		opacity: 0;
	}
	
	.vp-on-30 .card-container, .page-home .vp-on-30 .videos-list .card-container {
		opacity: 1;
		transform: translate(0);
  }
}
