.hero {
  color: get-color('brown');
}

.hero__copy-container {

}

.hero__image-container {
  line-height: 0;
}


@media #{$small-only} {
  .hero__heading {
    font-size: 1.75em;
  }

  h1.hero__heading {
    font-size: 1.75em;
  }
}

@media #{$medium-only} {
  .hero__subheading {
    font-size: 1.125rem;
  }
}

.hero {
  padding-top: 68px; // stickynav
}

