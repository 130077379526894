section.amiibo {
  // $img-pattern: 'patterns/green-triangles.png';
  text-align: center;
  position: relative;
  overflow: hidden;
  max-width: 100%;
  padding-bottom: 0;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: -150%;
    width: 400%;
    height: 100%;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    @include pattern-bg("green");
    z-index: -1;
  }

  .char--town {
    $img: "common/town-footer_2x.png";
    padding-bottom: 300px;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-image: image-url($img);
    background-size: auto 125%;
    position: relative;
    display: block;
    width: 100%;
    margin-top: 30px;
  }

  @media #{$small-only} {
    padding-top: 30px;
  }
}

.amiibo__heading {
  color: #fff;
  @extend .default-text-shadow;
}

.amiibo-lineup {
  margin-top: 30px;
  list-style: none;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;

  @media #{$large-up} {
    justify-content: space-between;
  }
}

.amiibo-cta {
  margin-top: 30px;
  margin-bottom: 20px;

  @media #{$medium-up} {
    margin-top: 0;
  }
}

.amiibo-lineup__item {
  text-align: center;
  position: relative;
  width: 50%;
  padding-left: 10px !important;
  padding-right: 10px !important;

  a {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
  }
}

.amiibo-lineup__image {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 30;
  line-height: 0;
  // padding-bottom: 75%;

  .amiibo-lineup__circle {
    content: "";
    background: #5ec34d;
    position: absolute;
    display: block;
    width: 100%;
    padding-bottom: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 50%;
    z-index: 29;
    bottom: 0;
    opacity: 0.8;
    transition: background 0.25s ease-out;
  }

  img {
    position: relative;
    z-index: 30;
    left: 0;
    right: 0;
    transform: scale(0.975);
    transition: transform 0.125s ease-out;
    max-width: 100%;
  }

  @at-root .amiibo-lineup__item:hover img {
    transform: scale(1.05) translateY(-5px);
  }
}

.amiibo-lineup__circlebg {
  display: none;
  @media #{$large-up} {
    width: 100%;
    padding-bottom: 100%;
  }

  @at-root .amiibo-lineup__item:hover .amiibo-lineup__circlebg {
    background: lighten(get-color("green"), 2.5%);
    opacity: 1;
  }
}

.amiibo-lineup__title {
  @include font-Arvo();

  background: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  margin-top: 15px;
  font-weight: bold;
  text-decoration: none;
  color: white;
  position: relative;
  z-index: 31;
  padding: 0 10px;
}

@media #{$medium-up} {
  section.amiibo {
    &:before {
      left: -100%;
      width: 300%;
    }

    // .amiibo-lineup__image:before {
    //   width: 100%;
    //   padding-bottom: 100%;
    // }

    .char--town {
      padding-bottom: 400px;
      background-size: auto 125%;
    }

    .amiibo-lineup__item {
      margin-bottom: 30px;
    }

    .amiibo-lineup__image {
      .amiibo-lineup__circle {
        width: 70%;
        padding-bottom: 70%;
      }

      img {
        max-width: 80%;
      }
    }

    .amiibo-lineup__title {
      font-size: 1.125rem;
    }
  }
}

@media #{$large-up} {
  section.amiibo {
    &:before {
      left: -50%;
      width: 200%;
    }

    // .char--town {
    //   padding-bottom: 26%;
    //   background-size: cover;
    // }

    .amiibo-lineup__image {
      .amiibo-lineup__circle {
        width: 90%;
        padding-bottom: 90%;
      }
    }
  }
}

@media #{$xlarge-up} {
  section.amiibo {
    .char--town {
      padding-bottom: 22%;
      background-size: cover;
    }

    .amiibo-lineup__image {
      .amiibo-lineup__circle {
        width: 85%;
        padding-bottom: 85%;
      }
    }
  }
}

@include reduce-motion {
  .amiibo-lineup__image img {
    transition-property: opacity;
  }
}
