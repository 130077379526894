.button {
  @extend .default-radius;
  @extend .default-shadow;

  appearance: none;
  background: transparent;
  border: none;
  display: inline-block;
  font-size: 1.115rem;
  font-weight: bold;
  padding: 15px 45px;
  text-align: center;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.button--wide, a.button--wide {
  padding: 15px 45px;
}

a.button--yellow, .button--yellow {
  background-color: #FDD10A;
  color: get-color('brown');

  &:hover {
    background-color: #FFE46A;
  }
}

a.button--red, .button--red {
  background-color: get-color('red');
  color: get-color('yellow');

  &:hover {
    background-color: darken(get-color('red'), 10%);
  }
}


.button--arrow {
  padding:15px 100px 15px 110px;
  position: relative;
  line-height: 1.7;

  // https://wethecollective.teamwork.com/index.cfm#tasks/9150064

  &:after {
    content:'';
    width: 11px;
    height: 11px;
    position: absolute;
    top:47%;
    border-top:solid 3px get-color('brown');
    border-right:solid 3px get-color('brown');
    right: 21px;
    transform: rotate(-315deg) translateY(-50%);
  }
}

.button--arrow--left {
  padding:15px 100px 15px 110px;
  &:after {
    border-top:solid 3px get-color('brown');
    border-right:solid 3px get-color('brown');
    left: 18px;
    transform: rotate(224deg) translateY(60%);
  }

  @media #{$small-only} {
    padding-left: 25%;
    padding-right: 25%;
  }
}

.button-container--wide {

  .button {
    width: 100%;
    max-width: 550px;
  }
}

.button--thin {
  padding-bottom: 8px;
  padding-top: 7px;
}