.news-error {
  display: none;
  justify-content: center;
  position: relative;

   div {
     background: white;
     border-radius: 12px;
     box-shadow: 0 5px 5px 0 rgba(0,0,0,0.30);
     padding: 36px 24px 38px;
   }
}

.news-error--shown {
  display: flex;
}