@import url("https://fonts.googleapis.com/css?family=Arvo:400,400i,700,700i");

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 16px;
  color: get-color("brown");
}

body {
  @include font-Arvo();
  @include font-smoothing();

  line-height: 1.75;
}

#wrap {
  overflow-x: hidden;

  button,
  a {
    &:focus {
      outline: 2px dashed #834b16;
      outline-offset: 2px;
      box-shadow: 0 0 6px 6px #ffe46a;

      &:not(:focus-visible) {
        box-shadow: none;
        outline: none;
      }
    }
  }
}

p,
.p {
  line-height: 1.5;
  margin-bottom: 0.8em;
}

em,
i {
  font-style: italic;
}

/* Anchors */
a,
a:visited {
  font-family: inherit;
  color: inherit;
}

/* Headers */
h1,
h2,
h3,
h4,
h5 {
  @include font-Arvo();

  margin: 0.65em 0 0.45em 0;
  line-height: 1.5;

  &:first-child {
    margin-top: 0;
  }

  i {
    font-family: sans-serif;
    font-style: normal;
  }
}

h1,
.title-1 {
  font-size: 2.5rem;
}

h2,
.title-2 {
  font-size: 3rem;
  font-size: 1.75rem;
}

h3,
.title-3 {
  font-size: 1.375rem;
}

h4,
.title-4 {
  font-size: 1.375rem;
  font-weight: bold;
}

h5,
.title-5 {
  font-size: 1rem;
  font-weight: bold;
}

section {
  padding-top: 20px;
  padding-bottom: 20px;
}

p,
.p {
  font-size: 1em;
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

@media #{$medium-up} {
  html {
    font-size: 14px;
  }

  p,
  .p {
    font-size: 1.258rem;
  }

  main section {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  h1,
  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.85;
  }
}

@media #{$large-up} {
  html {
    font-size: 16px;
  }

  p,
  .p {
    font-size: 1.125rem;
  }

  h1 {
    font-size: 2.25em;
  }

  h2 {
    font-size: 2.25rem;
  }

  h3 {
    font-size: 2.25rem;
  }
}
