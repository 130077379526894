.feature-image {
  text-align: center;
  padding-bottom: 0;
  position: relative;
  // bottom: -10px;

  .fancy-border img {
    border: solid 8px #fff;
  }
}

.feature-image__description-heading {
  font-weight: bold;
  display: block;
  clear: both;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 1.125em;
  text-decoration: none;
}

.feature-image__description {
  display: block;
  margin: 0 auto;
  max-width: 775px;
  line-height: 1.5;
  font-size: 1.125em;

  @media #{$small-only} {
    font-size: 1em;
    margin-bottom: 0;
  }
}

.feature-image__link {
  padding-top: 8px;
  padding-bottom: 8px;
  margin: 15px 0;
}
