.page-news {
  $img1x: "patterns/triangles-orange.png";
  $img2x: "patterns/triangles-orange-2x.png";

  background-color: #F47F33;
  background-image: image-url($img1x);
  background-size: image-width($img1x) auto;

  @media #{$retina} {
    background-image: image-url($img2x);
  }

  .header-main {
    filter: drop-shadow(0 6px 5px rgba(0,0,0,0.20));
    text-align: center;
  }

  .header-main__title {
    color: white;
    display: inline-block;
    filter: drop-shadow(0 6px 5px rgba(0,0,0,0.24));
    position: relative;
    transform: translateY(-50%);

    h1 {
      background-color: #961115;
      background-image: linear-gradient(90deg, #961115 0%, #C4161C 48%, #961115 97%);
      margin: 0;
      padding: 12px 110px;

      @supports (clip-path: polygon(0% 0%, 0% 0%, 0% 0%)) {
        --angle: 16px;

        clip-path: polygon(
          0 0,
          100% 0,
          calc(100% - var(--angle)) 50%,
          100% 100%,
          0 100%,
          var(--angle) 50%
        );
      }
    }
  }

  .section--news {
    margin-bottom: 72px;
    min-height: 100vh;
    padding-top: 16px;
    position: relative;
  }

  .load-more {
    // The reason this button is absolutely positioned, is because
    // we are setting min-height (100vh) on the parent content div,
    // and if we don't anchor this button to the bottom, we'll see it
    // for a second before the news items are loaded.
    // Could just hide this with JS until loaded, but this is totally fine.
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .load-more--hidden {
    display: none;
  }
}

.header-main__scene {
  // --curve-factor equates to a negative margin value,
  // and essentially contributes to a big ol' width on the element.
  --curve-factor: 60vw;

  align-items: center;
  display: flex;
  height: 220px;
  justify-content: center;
  mask-image: radial-gradient(
    ellipse at 50% 20%,
    rgba(0,0,0,1) 70.5%,
    rgba(0,0,0,0) 70.5%
  );
  margin: 0 calc(var(--curve-factor) * -1);
  position: relative;
}

.header-main__scene-bg {
  background-color: #FBBB37;
  background-image: image-url("news/scene-bg.jpg");
  background-position: 50% 100%;
  background-size: cover;
  height: 100%;
  position: absolute;
  top: 0;
  left: var(--curve-factor);
  width: calc(100% - var(--curve-factor) * 2);
}

.main-header__scene-logo {
  flex: 0 1 200px;
  margin: -20px 0 0;
  position: relative;
  z-index: 1;

  img {
    display: block;
    width: 100%;
  }
}

.main-header__scene-character {
  align-self: flex-start;
  margin-top: -12px;
  position: relative;
}

.button-js-clone {
  display: none;
}

.button--back {
  align-items: center;
  border: 6px solid #fff;
  border-radius: 3em;
  box-shadow: none;
  display: flex;
  margin: 0 auto;
  padding: 4px 36px 5px 30px;
  position: relative;
  transform: translateY(-50%);
  z-index: 1;

  &:last-child {
    transform: translateY(50%);
  }

  .button__arrow {
    margin-right: 12px;
  }
}

.esrb_container {
  display: flex;
  justify-content: center;
  color: black;
}

@media (hover: hover) {
  .button--back {
    .button__arrow {
      position: relative;
      transform: translateX(0);
      transition: transform .2s;
    }

    &:hover {
      .button__arrow {
        transform: translateX(-5px);
      }
    }
  }
}

@media #{$small-only} {
  .page-news {
    .header-main__title {
      h1 {
        font-size: 2rem;
        padding: 8px 50px;
      }
    }

    .section--news {
      padding-bottom: 64px;
    }
  }
}

@media #{$medium-up} {
  .header-main__scene {
    --curve-factor: 30vw;

    height: 310px;
  }

  .main-header__scene-logo {
    flex: 0 0 auto;
    margin-right: -1%;
  }
}