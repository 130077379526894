.reduce-motion-bar {
  background: #FFE46A;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 70; // keeps us above the news modal
}

.reduce-motion {
  appearance: none;
  background: none;
  border: none;
  border-radius: 4px;
  color: get-color('brown');
  padding: 2px 12px;
  font-size: 14px;
  text-transform: uppercase;

  @media #{$reduce-motion-query} {
    display: none;
  }
}

.reduce-motion__label {
  align-items: center;
  display: flex;

  .icon {
    margin-left: 10px;
  }
}

.reduce-motion__label--off {
  display: none;
}

@include reduce-motion() {
  .reduce-motion__label--off {
    display: flex;
  }

  .reduce-motion__label--on {
    display: none;
  }
}
