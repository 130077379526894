@import "../../settings/flex-grid", "../../mixins/helpers";

[data-news="loaded"] {
  .news-item {
    opacity: 1;
    transform: translate(0, 0);
    transition: transform 0.4s ease, opacity 0.4s ease;
  }
}

.news-item {
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
  opacity: 0;
  transform: translateY(30px);

  @for $i from 1 through 9 {
    &:nth-child(9n + #{$i}) {
      transition-delay: $i * 0.1s;
    }
  }

  article,
  a {
    display: block;
    height: 100%;
    text-decoration: none;
  }

  a {
    --card-scale: 1;

    transform: scale(var(--card-scale));
    transition: transform .2s;
  }
}

.news-article {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 5px 5px 0 rgba(0,0,0,0.30);
  display: flex;
  flex-direction: column;
  font-weight: bold;
  height: 100%;
  padding: 24px 12px 15px;
}

.news-article__date {
  display: block;
  font-size: 0.9rem;
  padding: 0 10px 12px;
}

.news-article__title {
  font-size: 1.1rem;
  line-height: 1.4;
  padding: 0 16px;
  margin-bottom: 20px;
}

.news-article__thumb {
  border-radius: 12px;
  display: block;
  margin-bottom: 12px;
  margin-top: auto;
  width: 100%;
}

.news-article__more {
  color: get-color("red");
  display: inline-block;
  font-weight: bold;
}

.youtube-embed {
  aspect-ratio: 16 / 9;
  margin-block-start: 1.8rem;
  
  iframe {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

@media (hover: hover) {
  .news-item a:hover {
    --card-scale: 1.02;

    border-radius: 12px;
    box-shadow: 0 0 0 6px #961115;
  
    .news-article__more {
      color: get-color("brown");
      text-decoration: underline;
    }
  }
}

@media #{$medium-only} {
  .news-item {
    flex: 0 0 304px;
    max-width: none;
  }

  .news-article__title {
    font-size: 0.9rem;
    padding: 0 8px;
  }
}

@media #{$medium-up} {
  .news-item {
    margin-left: 0;
    margin-right: 0;
  }
}

@media #{$medium-only} {
  .news-item {
    flex: 1 0 auto;
  }
}

@include reduce-motion {
  .news-item {
    transition-property: opacity;

    a {
      --card-scale: 1;
    }
  }
}
